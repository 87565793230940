<template>
  <div>
    <div class="crumbs"></div>
    <div class="container">
      <h1 class="title_01">酒店管理</h1>
      <div class="handle-box">
        <div class="mr9">
          <el-button
            type="primary"
            icon="el-icon-plus"
            class="handle-del mr10"
            @click="addhotelmetnod"
            >新增酒店</el-button
          >
        </div>
        <div class="mr11">
          <el-input
            v-model="hoteldatares.keyword"
            placeholder="用户名"
            class="handle-input"
          ></el-input>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="mr10"
            @click="handleSearch"
            >搜索</el-button
          >
        </div>
      </div>
      <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header" @selection-change="handleSelectionChange">
        <!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
        <el-table-column prop="id" label="ID" width="70" align="center" ></el-table-column>
        <el-table-column prop="list_order" label="排序" align="center">
          <template #default="{ row }">
            <span>{{
              row.list_order == null
                ? "-"
                : row.list_order
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="hotel_name" label="酒店名称" align="center" ></el-table-column>
        <el-table-column :formatter="timestampToTime" label="创建时间" align="center"></el-table-column>
        <el-table-column :formatter="timestampToTime2"  label="更新时间" align="center" ></el-table-column>

        <el-table-column label="状态" align="center">
          <template #default="{ row }">
            <span>{{
              row.status == 0
                ? ""
                : row.status == 1
                ? "正常营业"
                : "暂停营业"
            }}</span>
          </template>
        </el-table-column>


        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
        background layout="total, prev, pager, next" 
        :current-page="hoteldatares.page_index" 
        :page-size="hoteldatares.page_size" 
        :total="pageTotal" 
        @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { get_info, post_hotel_data } from "../../api/index";
export default {
  name: "basetable",
  data() {
    return {
      hoteldatares: {
        type: "hotel",
        id: "",
        keyword: this.keyword, // 搜索
        page_index: 1,
        page_size: 5,
      },

      tableData: [],
      multipleSelection: [],
      delList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      get_info(this.hoteldatares).then((res) => {
        this.tableData = res.data.list;
        this.pageTotal = res.data.count;
      });
    },
    // 触发搜索按钮
    handleSearch() {
      get_info(this.hoteldatares).then((res) => {
        console.log(res);
        this.getData();
      });
    },
    // 删除操作
    handleDelete(index, row) {
      // 删除酒店
      var addhotelData = {
        type: "delete",
        id: row.id,
        token: localStorage.getItem("token"),
      };
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          post_hotel_data(addhotelData).then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.getData();
            }else{
              this.$message.error(res.msg);
            }
          });
          // this.tableData.splice(index, 1);
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //创建时间格式化
    timestampToTime(row) {
      if(row.create_time == 0){
        return "-"
      }else{
        var date = new Date(row.create_time * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate()+1) :date.getDate()+1)+ ' ';
        var h = (date.getHours()+ 1 < 10 ? '0'+(date.getHours()+1) : date.getHours()+1) + ':';
        var m = (date.getMinutes()+1 < 10 ? '0'+(date.getMinutes()+1) : date.getMinutes()+1) + ':';
        var s = date.getSeconds();
        return Y+M+D+h+m+s;
      }
    },
    timestampToTime2(row) {
      if(row.update_time == 0){
        return "-"
      }else{
        var date = new Date(row.update_time * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate()+1) :date.getDate()+1)+ ' ';
        var h = (date.getHours()+ 1 < 10 ? '0'+(date.getHours()+1) : date.getHours()+1) + ':';
        var m = (date.getMinutes()+1 < 10 ? '0'+(date.getMinutes()+1) : date.getMinutes()+1) + ':';
        var s = date.getSeconds();
        return Y+M+D+h+m+s;
      }
    },
    // 新增酒店
    addhotelmetnod() {
      this.$router.push({ path: "/addhotel" });
    },
    // 编辑操作
    handleEdit(index, row) {
      console.log(row);
      this.$router.push({
        path: "/addhotel",
        query: { id: row.id },
      });
    },
    // 分页导航
    handleCurrentChange(val) {
      this.hoteldatares.page_index = val;
      console.log(this.hoteldatares.page_index)  //点击第几页
      this.getData();
    },
  },
};
</script>

<style scoped>
.title_01 {
  margin-bottom: 20px;
}
.handle-box {
  margin-bottom: 77px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  margin-right: 10px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr9 {
  float: left;
}
.mr10 {
  margin-right: 10px;
  float: right;
}
.mr11 {
  float: right;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
